.MetadataEditor {
  &>.MuiFormControl-root {
    display: block;
    width: 100%;
  }

  &>.MuiInput-root {
    width: 100%;
  }
}

.sidebar>* {
  margin-bottom: 20px;
}

.AudioVariantChooser {
  position: relative;

  &>.audio-variant-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.audio-variant-property-fields {
  display: flex;
}

.SectionEditor {
  position: relative;
}

.top-right-buttons {
  position: absolute;
  right: 0;
  top: -0.3em;
}

.Section {
  --verse-number-width: 120px;
  &>.manifestation-edit {
    position: relative;

    &>* {
      bottom: 0;
    }

    &>.section-label {
      display: inline-block;
      width: calc(100% - var(--verse-number-width));
      margin: 0;
      padding-top: .6em;
    }

    &>.verse-number {
      width: var(--verse-number-width);
      input {
        font-size: 1.2em;
        font-weight: bold;
      }
    }
  }

  &>.manifestation-edit>.section-edit-buttons {
    display: none;
  }

  &:hover>.manifestation-edit>.section-edit-buttons {
    display: block;
    position: absolute;
    width: calc(100% - var(--verse-number-width));
    min-width: fit-content;
    background-color: rgba(255, 255, 255, .7);
    text-align: right;
    white-space: nowrap;
    top: 0.4em;
  }

  .ClipChooser {
    position: relative;

    &:not(:hover).no-clip {
      opacity: .4;
    }

    &>.clip-edit-buttons {
      position: absolute;
      display: none;
      top: -0.5em;
      ;
      right: 0;
    }

    &:hover>.clip-edit-buttons {
      display: block;
    }
  }
}

.error {
  background-color: #f77;
}

.warning {
  background-color: #fa0;
}
[data-type=SplitPane][data-split=vertical] {
  &>div:first-of-type {

    overflow: auto;
  }

  &>div>.content,
  #XmlEditor,
  .CodeMirror {
    height: 100%;
  }
}

[data-type=SplitPane][data-split=horizontal]>div:first-of-type {
  overflow: auto;
}

.Verovio>svg:not(:first-of-type) {
  margin-top: 20px;
}
// The GitHub REST API does not give us a modification date, so hide the column
.rendered-file-browser tr.file>td.modified {
  display: none;
}

// JK from here on
.App {
  height: 100vh;
  display: flex;
  flex-flow: column;

  header.appHeader {
    padding: .8rem 3rem .8rem 1.2rem;
    background-color: #e5e5e5;
    border-bottom: .5px solid #999999;
    font-size: 1.2rem;
    font-weight: 700;
    position:sticky;
    top:0;
    z-index: 10;
    box-shadow: 0 0 .5rem #00000099;

    &>.help {
      float: right;
    }
  }

  div.splitPane {
    flex: 1;

    &>div:first-of-type {
      padding: 1rem;
    }

    div.leftsideBox { // the box for loading stuff from GitHub
      border: .5px solid #aaaaaa;
      background-color: #f5f5f5;
      padding: .5rem;
      border-radius: .3rem;

      &:before {
        display: block;
        font-weight: 500;
        margin-bottom: .5rem;
      }

      &.IoButtons:before {
        content: 'GitHub';
      }

      &.MetadataEditor:before {
        content: 'Metadaten';
      }

      &.AudioVariantChooser:before {
        content: 'Audio-Version';
      }

      &.SectionEditor {
        margin: 0 0 0 1rem;
        &:before {
          content: 'Strophen';
        }
      }
    }
  }

  .SectionEditor {

    .Section {
      border: .5px solid #aaaaaa;
      border-radius: .5rem;
      background-color: #ffffff;
      padding: .5rem;
      margin: .5rem 0;
    }
  }
}

.MuiDialog-paper > *:not(.MuiDialogTitle-root) {
  padding: 0 1em;
}

.wait > .MuiDialogContent-root {
  width: 100%;
  height: 100%;

  & > .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

body>iframe {
  // For some reason, when auto-updating during development, an iframe appears
  // that blocks everything
  display: none;
}